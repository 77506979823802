import { LegalCopyBlock } from '@animoto/components/';
import classNames from 'classnames';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import requiredIf from 'react-required-if';

import LegalRichText from '../LegalRichText';
import contentCache from './contentCache.json';
import { legalCopy, marginContainer } from './LegalCopyBlockModule.module.css';

export default function LegalCopyBlockModule({
  className,
  contentKey,
  data
}) {
  const {
    body,
    hasMarginBottom
  } = data || contentCache[contentKey];
  const classes = classNames(className, legalCopy, {
    [marginContainer] : hasMarginBottom
  });

  return (
    <LegalCopyBlock
      className={classes}
    >
      <LegalRichText>{body}</LegalRichText>
    </LegalCopyBlock>
  );
}

export const LegalCopyBlockDataPropsObject = {
  body : PropTypes.shape({
    raw : PropTypes.string
  }).isRequired,
  hasMarginBottom : PropTypes.bool
};

LegalCopyBlockModule.propTypes = {
  className  : PropTypes.string,
  contentKey : requiredIf(PropTypes.string, (props) => !props.data),
  data       : requiredIf(PropTypes.shape(LegalCopyBlockDataPropsObject), (props) => !props.contentKey)
};

LegalCopyBlockModule.defaultProps = {
  className  : null,
  data       : null,
  contentKey : null
};

export const LegalCopyBlockModuleData = graphql`
  fragment LegalCopyBlockModuleData on ContentfulComponentLegalCopyBlock {
    body {
      raw
    }
    hasMarginBottom
  }
`;
