import InlineLink from '@animoto/components/lib/es/InlineLink';
import LegalBoldCopy from '@animoto/components/lib/es/Typography/LegalBoldCopy';
import LegalCopy from '@animoto/components/lib/es/Typography/LegalCopy';
import LegalSectionTitle from '@animoto/components/lib/es/Typography/LegalSectionTitle';
import LegalSmallTitle from '@animoto/components/lib/es/Typography/LegalSmallTitle';
import LegalSubsectionTitle from '@animoto/components/lib/es/Typography/LegalSubsectionTitle';
import LegalTitle from '@animoto/components/lib/es/Typography/LegalTitle';
import LegalUnderlineCopy from '@animoto/components/lib/es/Typography/LegalUnderlineCopy';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import PropTypes from 'prop-types';
import React from 'react';

import {
  paragraph,
  ulList
} from './LegalRichText.module.css';

const options = {
  renderMark : {
    [MARKS.BOLD]      : (text) => <LegalBoldCopy>{text}</LegalBoldCopy>,
    [MARKS.UNDERLINE] : (text) => <LegalUnderlineCopy>{text}</LegalUnderlineCopy>
  },
  renderNode : {
    [BLOCKS.PARAGRAPH]  : (node, children) => <LegalCopy className={paragraph}>{children}</LegalCopy>,
    [INLINES.HYPERLINK] : (node, children) => <InlineLink href={node.data.uri}>{children}</InlineLink>,
    [BLOCKS.LIST_ITEM]  : (node, children) => <LegalCopy element="li">{children}</LegalCopy>,
    [BLOCKS.UL_LIST]    : (node, children) => <LegalCopy className={ulList} element="ul">{children}</LegalCopy>,
    [BLOCKS.HEADING_1]  : (node, children) => <LegalTitle>{children}</LegalTitle>,
    [BLOCKS.HEADING_2]  : (node, children) => <LegalSectionTitle>{children}</LegalSectionTitle>,
    [BLOCKS.HEADING_3]  : (node, children) => <LegalSubsectionTitle>{children}</LegalSubsectionTitle>,
    [BLOCKS.HEADING_4]  : (node, children) => <LegalSmallTitle>{children}</LegalSmallTitle>
  }
};

export default function LegalRichText({ children }) {
  return renderRichText(children, options);
}

LegalRichText.propTypes = {
  children : PropTypes.object.isRequired // eslint-disable-line react/forbid-prop-types
};
