import { Label, LegalCopyBlock } from '@animoto/components/';
import classNames from 'classnames';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import requiredIf from 'react-required-if';

import LegalRichText from '../LegalRichText';
import contentCache from './contentCache.json';
import { label, marginContainer } from './LegalSidebarMenuModule.module.css';

export default function LegalSidebarMenuModule({
  className,
  data,
  contentKey
}) {
  const {
    hasMarginBottom,
    link,
    title
  } = data || contentCache[contentKey];
  const classes = classNames(className, {
    [marginContainer] : hasMarginBottom
  });

  return (
    <div className={classes}>
      <Label className={label} withSpacing>
        {title}
      </Label>
      <LegalCopyBlock>
        <LegalRichText>{link}</LegalRichText>
      </LegalCopyBlock>
    </div>
  );
}

export const LegalSidebarMenuDataPropsObject = {
  hasMarginBottom : PropTypes.bool,
  link            : PropTypes.shape({
    raw : PropTypes.string
  }).isRequired,
  title : PropTypes.string
};

LegalSidebarMenuModule.propTypes = {
  className  : PropTypes.string,
  contentKey : requiredIf(PropTypes.string, (props) => !props.data),
  data       : requiredIf(PropTypes.shape(LegalSidebarMenuDataPropsObject), (props) => !props.contentKey)
};

LegalSidebarMenuModule.defaultProps = {
  className  : null,
  data       : null,
  contentKey : null
};

export const LegalSidebarMenuModuleData = graphql`
  fragment LegalSidebarMenuModuleData on ContentfulComponentLegalSidebarMenu {
    hasMarginBottom
    link {
      raw
    }
    title
  }
`;
